<template>
  <div class="tw-p-5">
<!--    <div class="lg:tw-w-3/12 md:tw-w-5/12 tw-pt-20" style="margin: 0 auto">-->
<!--      <validation-observer ref="observer" v-slot="{ handleSubmit }">-->
<!--        <form @submit.prevent="handleSubmit(login)">-->
<!--        <div class="tw-my-8">-->
<!--          <h4 class="find">Login</h4>-->
<!--        </div>-->
<!--        <div class="tw-mt-4">-->
<!--          <small>Email Address</small>-->
<!--          <validation-provider-->
<!--              v-slot="{ errors }"-->
<!--              name="email"-->
<!--              rules="required">-->
<!--          <v-text-field-->
<!--              id="email"-->
<!--              hide-details-->
<!--              label="Enter your email address"-->
<!--              v-model="user.email"-->
<!--              @input="value => { user.email = value.toLowerCase();}"-->
<!--              solo/>-->
<!--            <span class="err">{{ errors[0] }}</span>-->
<!--          </validation-provider>-->
<!--        </div>-->
<!--        <br>-->
<!--        <div>-->
<!--          <small>Password</small>-->
<!--          <validation-provider-->
<!--              v-slot="{ errors }"-->
<!--              name="password"-->
<!--              rules="required">-->
<!--          <v-text-field-->
<!--              id="password"-->
<!--              hide-details-->
<!--              label="••••••••••••••"-->
<!--              v-model="user.password"-->
<!--              :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"-->
<!--              @click:append="() => (value = !value)"-->
<!--              :type="value ? 'text' : 'password'"-->
<!--              solo/>-->
<!--            <span class="err">{{ errors[0] }}</span>-->
<!--          </validation-provider>-->
<!--        </div>-->
<!--        <div class="tw-flex tw-items-center tw-justify-end tw-mt-2 tw-mb-7">-->
<!--          <span class="forget" @click="$router.push({name:'ResetPassword'})">Forgot Password?</span>-->
<!--        </div>-->
<!--        <div class="tw-mt-2">-->
<!--          <BaseButton :loading="isLoading" :block="true" button-text="Log In"/>-->
<!--        </div>-->
<!--        <div class="tw-flex tw-items-center">-->
<!--          <v-divider/>-->
<!--          <span class="or tw-m-4">Or</span>-->
<!--          <v-divider/>-->
<!--        </div>-->
<!--        <div>-->

<!--        </div>-->
<!--      </form>-->
<!--      </validation-observer>-->
<!--      <BaseButton :block="true" @click="loginWithGoogle" type="button" :outlined="true" button-text="Login with Google">-->
<!--        <template #left-icon>-->
<!--          <GoogleIcon/>-->
<!--        </template>-->
<!--      </BaseButton>-->
<!--&lt;!&ndash;      <a href="https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=77lmgaujr34tt6&scope=r_liteprofile&state=123456&redirect_uri=http://localhost:8080" target="_self">&ndash;&gt;-->
<!--&lt;!&ndash;      <BaseButton class="tw-my-4"  :block="true" :outlined="true" button-text="Login with LinkedIn">&ndash;&gt;-->
<!--&lt;!&ndash;        <template #left-icon>&ndash;&gt;-->
<!--&lt;!&ndash;          <LinkedInIcon/>&ndash;&gt;-->
<!--&lt;!&ndash;        </template>&ndash;&gt;-->
<!--&lt;!&ndash;      </BaseButton>&ndash;&gt;-->
<!--&lt;!&ndash;      </a>&ndash;&gt;-->
<!--    </div>-->

  </div>
</template>

<script>
// import BaseButton from "@/components/reusables/Ba
// import LinkedInIcon from "@/components/icons/LinkedInIcon";
import {mapGetters} from 'vuex'
import oauth from "@/mixins/oauth.mixin"

export default {
  name: "Login",
  mixins: [oauth],
  // components: {GoogleIcon, BaseButton},
  data(){
    return{
      value: false,
      user:{
        email:'',
        password:''
      }
    }
  },
  computed:{
    ...mapGetters('onboarding',['isLoading'])
  },
  methods:{
    login(){
      this.$store.dispatch('onboarding/login',this.$route.query.token)
    },
    async loginWithGoogle(){
      await this.googleAuth()
    },
    async loginWithLinkedIn(){
      await this.linkedInAuth()
    }
  },
  async created() {
    await this.login()
  }
}
</script>

<style scoped>
.find {
  font-family: IBM Plex Serif, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #001343;
}


.or{
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #475661;
}
.forget{
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
  color: #008EEF;
}
.err{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: red;

}
</style>
