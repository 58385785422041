<template>
<div>
<!--  <OnboardingHeader>-->
<!--    <template #right-header-component>-->
<!--      <div class="tw-flex tw-items-center">-->
<!--        <span class="text">Don’t have an account?  <span class="action" @click="$router.push({name:'SignUpView'})">Sign Up</span></span>-->

<!--      </div>-->
<!--    </template>-->

<!--  </OnboardingHeader>-->
  <div class="lg:tw-mt-16">
    <Login/>
  </div>
</div>
</template>

<script>
// import Header from "@/components/reusables/Header";
import Login from "@/components/onboarding/Login";
// import OnboardingHeader from "@/components/reusables/OnboardingHeader";
export default {
name: "LoginView",
  components: {Login}
}
</script>

<style scoped>
.text{
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #8692A6;
}
.action{
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: #008EEF;
  cursor: pointer;
  /*margin-left: 13px;*/
}

</style>
